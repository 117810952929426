import React from "react"
import Layout from "../layout"
import { BackgroundLayout, Section, StyledContainer, StyledGrid } from "./style"

import Header from "./header"

export const DrugLayout = ({ navigation, title, subtitle, children }) => {
  const nav = navigation || {}
  return (
    <Layout backgroundColor="#fff" navigation={nav}>
      <BackgroundLayout>
        <Header title={title} subtitle={subtitle} />
        <Section>
          <StyledContainer>
            <StyledGrid>{children}</StyledGrid>
          </StyledContainer>
        </Section>
      </BackgroundLayout>
    </Layout>
  )
}

export default DrugLayout
