import React from "react"
import { DrugListItems } from "./items"
import { DirectoryContainer, Pagination, Dot } from "./style"
import Seo from "../../components/seo"

export const DrugList = props => {
  return (
    <DirectoryContainer>
      <Seo
        title="Liste des médicaments"
        description="Liste des médicaments disponible sur goodmed.com"
        lang="fr"
      />
      <Pagination>
        <Dot current={0 === props.actualPage}>
          <a href={`/liste-des-medicaments/populaires`}>Populaires</a>
        </Dot>
        {Array.from({ length: props.nbPages }).map((value, index) => (
          <Dot key={index} current={index + 1 === props.actualPage}>
            <a href={`/liste-des-medicaments/${index + 1}`}>{index + 1}</a>
          </Dot>
        ))}
      </Pagination>
      <DrugListItems drugs={props.drugs} />
    </DirectoryContainer>
  )
}
