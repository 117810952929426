import React from "react"

import {
  DirectoryTopCategory,
  DirectoryTopCategoryLabel,
  DirectoryTree,
  DirectoryTreeContainer,
  DirectoryTopCategoryButton,
} from "./style"

export const DrugListItems = props => (
  <DirectoryTreeContainer>
    {props.drugs?.map((drug, index) => (
      <DirectoryTree key={index} href={`/medicaments/${drug.id}`}>
        <DirectoryTopCategory>
          <DirectoryTopCategoryLabel>{drug.label}</DirectoryTopCategoryLabel>
          <DirectoryTopCategoryButton>Consulter</DirectoryTopCategoryButton>
        </DirectoryTopCategory>
      </DirectoryTree>
    ))}
  </DirectoryTreeContainer>
)
