import styled from "styled-components"
import { variables, mediaQueries } from "@styles"

export const Pagination = styled.nav`
  display: block;
  text-align: center;
`

export const Dot = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
  margin-top: 6px;

  span,
  a {
    font-size: 16px;
    line-height: 16px;

    display: block;
    padding: 8px 12px;
    border-radius: 16px;
    font-family: "stolz";

    ${({ current }) =>
      current
        ? `
      background-color: ${variables.blue};
      color: #fff;
      border: 1px solid transparent;
    `
        : `
      color: ${variables.blue};
      border: 1px solid #E5E5E5;
      background-color: #fff;
      `}

    &:hover {
      background-color: ${variables.blue};
      color: #fff;
      border: 1px solid transparent;
    }
  }
`

export const DirectoryContainer = styled.div`
  border-radius: 80px;
  padding: 16px;
  padding-top: 20px;
  width: 100%;
  box-shadow: 0 -40px 30px rgba(0, 0, 0, 0.08);
  position: relative;
  margin-top: -90px;
  background-color: #fff;
  z-index: 10;

  @media ${mediaQueries.medium} {
    padding: 90px 80px;
  }
`

export const DirectorySearch = styled.div`
  border-radius: 16px;
  padding: 4px;
  width: 100%;
  background-color: ${variables.lightBlue};
  color: ${variables.blue};
  display: flex;
  align-items: center;
  padding: 15px;

  span {
    margin-left: 10px;
  }
`

export const DirectorySearchIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  margin: 28px 0;
`

export const DirectoryTreeContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const DirectoryTree = styled.a`
  margin-top: 20px;
  width: 100%;
  display: block;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  transition: all ease-in-out 150ms;
  border: 1px solid #e5e5e5;
  color: #171717;

  &:hover {
    background-color: ${variables.blue};
    color: #fff;
    border: 1px solid transparent;
  }
`

export const DirectoryTopCategory = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const DirectoryTopCategoryIcon = styled.div`
  flex-shrink: 0;
`

export const DirectoryTopCategoryLabel = styled.div`
  width: 100%;
  font-weight: medium;
  font-family: "stolz";
  margin-left: 8px;
  @media ${mediaQueries.preMedium} {
    word-break: break-word;
  }
`

export const DirectoryTopCategoryButton = styled.div`
  flex-shrink: 0;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: ${variables.lightBlue};
  color: ${variables.blue};
  font-weight: 500;
  font-family: "stolz";
  cursor: pointer;
`

export const DirectorySubCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
`
export const DirectorySubCategoryLabel = styled.div``

export const DirectorySubCategoryMoreContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const DirectorySubCategoryMoreArrow = styled.div`
  margin-left: 16px;
`

export const DirectorySubCategoryMoreLabel = styled.div`
  font-weight: 500;
  padding: 2px 0;
  border-bottom: 2px solid;
  font-family: "stolz";
`

export const DirectoryDrugsContainer = styled.div`
  padding-left: 30px;
  width: 100%;
`
export const DirectoryDrug = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;

  &hover {
    background-color: grey;
  }
`
export const DirectoryDrugName = styled.div``
export const DirectoryDrugButton = styled.div``
