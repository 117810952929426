import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Image, Title, DrugContainer, Link, MobileCTA } from "./style"
import { ButtonBlue, P0 } from "@styles"

const Header = ({ title, subtitle }) => {
  return (
    <DrugContainer>
      <div>
        <Title as="p" style={{ maxWidth: 420 }}>
          {title}
        </Title>
        {subtitle && <P0 style={{ maxWidth: 420 }}>{subtitle}</P0>}
        <MobileCTA>
          <div>
            <div
              onClick={() =>
                window &&
                window.open(
                  "https://app.adjust.com/y0h912n?campaign=Goodmed-Website&adgroup=Website&creative=Fiches-Médicaments"
                )
              }
              className="btn"
            >
              <ButtonBlue picto="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwIDIuNXYxMS44NzVNNC4zNzUgOC43NUwxMCAxNC4zNzVsNS42MjUtNS42MjVNMy4xMjUgMTYuODc1aDEzLjc1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS43NSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+">
                <span>Télécharger Goodmed</span>
              </ButtonBlue>
            </div>
          </div>
          <div style={{ marginTop: 24, position: "relative" }}>
            <Link
              onClick={() =>
                window && window.open("https://dub.sh/prodregister")
              }
            >
              Je suis un professionnel de santé
            </Link>
          </div>
        </MobileCTA>
      </div>
      <Image>
        <StaticImage
          src="../../images/drugs.png"
          alt="information medicament"
          sizes={`1300px`}
        />
      </Image>

      <svg width="0" height="0" viewBox="0 0 1163 765">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.001047 .001204)"
            id="drug-info"
          >
            <path
              d="M250.186 762.457C537.894 767.117 825.727 763.748 1113.51 764.739C1147.35 764.862 1163.25 758.357 1162.57 738C1162.28 729.533 1161.5 726 1161.5 704C1161.5 682 1163 0 1163 0H211.276C219.77 17.5938 227.811 34.084 235.179 48.9766C260.023 99.1918 249.542 130.659 211.734 165.038C161.465 210.751 110.017 256.354 66.8981 308.49C-5.98341 396.563 -19.026 497.812 26.356 603.343C68.3281 700.892 145.342 760.741 250.186 762.457Z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </DrugContainer>
  )
}

export default Header
