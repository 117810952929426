import React from "react"
import { graphql } from "gatsby"
import { DrugList } from "./drugs-list-components"
import DrugLayout from "../components/drug-layout"

import { GridItem } from "../styles"

const DrugsListTemplate = ({ data, pageContext }) => {
  const navigation = data.prismicNavigation || {}

  return (
    <DrugLayout navigation={navigation} title="Liste des médicaments">
      <GridItem col={{ md: 12, lg: 12 }}>
        <DrugList
          drugs={pageContext.drugs}
          nbPages={pageContext.nbPages}
          actualPage={pageContext.actualPage}
        />
      </GridItem>
    </DrugLayout>
  )
}

export default DrugsListTemplate

export const query = graphql`
  query GetDrugsListTemplateQuery($lang: String) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
