import styled from "styled-components"
import { Container, mediaQueries, Grid, T2, variables } from "@styles"

const BackgroundLayout = styled.div`
  position: relative;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMSIgaGVpZ2h0PSI2MjQiIHZpZXdCb3g9IjAgMCAxOTIxIDYyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzFfNCkiPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDFfMV80KSI+CjxwYXRoIGQ9Ik0xODg4LjU3IC00NTYuOTI4TC01NS40OTQ2IC0zMjAuOTg2TDExLjMzMDIgMzMxLjQyMUMyOS4wNzMzIDUwNC42NDYgMTg0LjI3NCA2MzUuMjI1IDM1Ny45ODEgNjIzLjA3OEwxNjczIDUzMS4xMjRDMTg0Ni43MSA1MTguOTc3IDE5NzMuMTQgMzY4LjcwNCAxOTU1LjQgMTk1LjQ4TDE4ODguNTcgLTQ1Ni45MjhaIiBmaWxsPSIjRkVGOEVDIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xXzQiPgo8cmVjdCB3aWR0aD0iMTkyMSIgaGVpZ2h0PSI2MjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjxjbGlwUGF0aCBpZD0iY2xpcDFfMV80Ij4KPHJlY3Qgd2lkdGg9IjE5MjEiIGhlaWdodD0iODI5IiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMjA1KSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=);
  background-size: 1500px;
  background-position-x: center;
  min-height: 650px;
  @media all and (min-width: 1441px) {
    background-size: 1900px;
  }
`

const MobileCTA = styled.div`
  @media ${mediaQueries.medium} {
    display: none;
  }
`

const Link = styled.div`
  display: inline-flex;
  margin-bottom: 5px;
  position: relative;
  color: ${variables.darkBlue};
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  box-shadow: 0 2px 0px -1px ${variables.darkBlue};
  line-height: 1.25;
`

const Section = styled.section`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 1225px;
  width: 100%;
  border-radius: 50px;

  margin: 0 auto;
  @media ${mediaQueries.preNavbar} {
    padding: 0;
  }
`

const DrugContainer = styled(Container)`
  max-width: 1220px;
  display: flex;
  margin: 0 auto;
  padding: 90px 16px 140px;
  @media ${mediaQueries.medium} {
    padding: 170px 32px;
  }
`

const StyledContainer = styled(Container)`
  max-width: 1220px;
  display: flex;
  margin: 0 auto;
  padding: 0;
  width: 100%;
`

const StyledGrid = styled(Grid)`
  @media ${mediaQueries.preMedium} {
    flex-direction: column-reverse;
  }
`

const Title = styled(T2)`
  margin-bottom: 32px;
  font-family: "stolz", sans-serif;
  font-weight: 700;
`

const Image = styled.div`
  clip-path: url(#drug-info);
  position: absolute;
  right: 0;
  top: 0;
  width: 700px;
  @media ${mediaQueries.preNavbar} {
    z-index: -1;
    img {
      display: none;
    }
  }
`

export {
  StyledContainer,
  DrugContainer,
  BackgroundLayout,
  Section,
  MobileCTA,
  StyledGrid,
  Title,
  Image,
  Link,
}
